import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as ProductTableName } from '@spa-ec/components/ProductTable/ProductTable.theme';
import { ProductShelfCategoryKey } from '@spa-core/store/products/constants';
import { NAME as ProductRowName } from '@spa-ec/components/ProductTable/Rows/ProductRow.theme';
import { NAME as USPWrapperName } from '@spa-ec/components/ContentSlot/components/USPWrapper/USPWrapper.theme';
import { NAME as PrisjaktCheapestName } from '@spa-ec/components/PrisjaktCheapest/PrisjaktCheapest.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    sideContentWidth: '22%',
    sideContentPadding: 'px-4',
};
const headerTheme = {
    showCountrySelector: false,
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.col.tertiary.fg,
    bg: 'white',
    showLogoutIcon: false,
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showProductDetails: true,
    showPerKronaInCartTable: false,
};
const productTableTheme = {
    categoriesOrder: [
        ProductShelfCategoryKey.ONE_BUY_PRODUCTS,
        ProductShelfCategoryKey.OPTIMUM,
        ProductShelfCategoryKey.RECOMMENDED,
        ProductShelfCategoryKey.BUDGET,
        ProductShelfCategoryKey.ORIGINAL,
        ProductShelfCategoryKey.UPSELLING,
        ProductShelfCategoryKey.FREE_CATEGORIES_LIST,
    ],
};
const productRowTheme = {
    showOneKronaSticker: true,
};
const uspWrapperTheme = {
    maximumItemsIpad: 2,
};
const prisjaktCheapestTheme = {
    storeIds: { 'patroner-se': 2415 },
};
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo',
        paths: {
            b2c: {
                default: '/patroner_logo.svg',
            },
            b2b: {
                default: '/patroner_logo.svg',
            },
        },
    },
};
export default {
    [ContentPageName]: contentPageTheme,
    [HeaderName]: headerTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [ProductTableName]: productTableTheme,
    [ProductRowName]: productRowTheme,
    [USPWrapperName]: uspWrapperTheme,
    [PrisjaktCheapestName]: prisjaktCheapestTheme,
    [SiteLogoName]: siteLogoTheme,
};
